export const social = [
    {
        name: "instagram",
        link: "https://www.instagram.com/araysociety/",
        logo: "/social/instagram.png"
    },
    {
        name: "facebook",
        link: "https://www.facebook.com/wearearay/",
        logo: "/social/facebook.png"
    },
    {
        name: "linkedin",
        link: "https://www.linkedin.com/company/aray-group/",
        logo: "/social/linkedin.png"
    }
]

export const externalUrls = {
    signup: "https://community.araysociety.com/signup",
    login: "https://community.araysociety.com/login",
    appleStore: "https://apps.apple.com/us/app/aray-society/id6445877752",
    playStore:
        "https://play.google.com/store/apps/details?id=com.araysociety.app"
}
