import React from "react"
import "./style.css"

import { social } from "../../utils/social"
import { Link } from "gatsby"

const Footer = () => {
    return (
        <div className="aray__footer">
            <div className="aray__footer__wrapper">
                <img
                    src="/logo_r.png"
                    alt="logo"
                    className="aray__footer--logo"
                />

                <div className="footer__column">
                    <p className="footer__title">Social Media</p>
                    <div className="footer__column__social">
                        {social.map(({ link, logo }) => (
                            <a
                                key={logo}
                                href={link}
                                className="footer__column__social--icon"
                                target="blank"
                            >
                                <img alt="social" src={logo} />
                            </a>
                        ))}
                    </div>
                    <div className="footer__terms">
                        <Link to='/privacy'>Privacy Policy</Link>
                        <Link to='/terms'>Terms & Conditions</Link>
                    </div>
                </div>
                <div className="footer__column">
                    <p className="footer__title">Contact</p>
                    <div className="footer__column__address">
                        <p>info@araysociety.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
