import * as React from "react"
import PropTypes from "prop-types"

import Navbar from "./NavBar"
import "./layout.css"

import Footer from "./Footer"

const stroke1 = "/strokes/stroke1.png"
const stroke2 = "/strokes/stroke2.png"
const stroke3 = "/strokes/stroke3.png"
const stroke4 = "/strokes/stroke4.png"

const Layout = ({ children, withStrokes, onBodyClick }) => {
    return (
        <div onClick={onBodyClick}>
            <Navbar />
            <div>
                <main className="main">
                    {withStrokes && (
                        <div className="stroke-layer">
                            <img
                                src={stroke1}
                                alt="stroke"
                                className="stroke stroke-1"
                            />
                            <img
                                src={stroke2}
                                alt="stroke"
                                className="stroke stroke-2"
                            />
                            <img
                                src={stroke3}
                                alt="stroke"
                                className="stroke stroke-3"
                            />
                            <img
                                src={stroke4}
                                alt="stroke"
                                className="stroke stroke-4"
                            />
                        </div>
                    )}
                    <div className="main-wrapper">{children}</div>
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
