import React from "react"
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
// import Button from "../Button"
import { externalUrls } from "../../utils/social"
import appleStoreButton from "../../images/stores/apple_store.png"
import playStoreButton from "../../images/stores/play_store.png"

const NavBar = () => {
    const location = useLocation()
    const currentPath = location.pathname
    const currentPathArr = currentPath.split(`/`)
    const navOption = [
        {
            text: "Home",
            to: "/"
        },
        {
            text: "About us",
            to: "/about"
        },
        {
            text: "Become a partner",
            to: "/partner"
        },
        // {
        //     text: "Aray app",
        //     to: "/app"
        // },
        {
            text: "Blog",
            to: "/blog"
        },
        {
            text: "Event",
            to: "/event"
        }
    ]

    const isColored =
        currentPathArr.includes("blog") || currentPathArr.includes("event")

    return (
        <div
            className={`navbar__container ${isColored && "coloredBackground"}`}
        >
            <div className="navbar__container__wrapper">
                <Link to="/">
                    <StaticImage
                        src="../../images/aray_logo.png"
                        alt="logo"
                        className="navbar__container--logo"
                        placeholder="transparent"
                    />
                </Link>
                <div className="navbar__container--right">
                    <div className="menu-options__wrapper">
                        {navOption.map(option => (
                            <Link
                                key={option.text}
                                className="menu-options__option"
                                to={option.to}
                                partiallyActive={option.to !== "/"}
                                activeStyle={{
                                    color: "#631726",
                                    fontWeight: "bold"
                                }}
                            >
                                {option.text}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="button-wrapper">
                    <div className="store-container">
                        <a href={externalUrls.appleStore}>
                            <img
                                alt="img"
                                src={appleStoreButton}
                                className="store-apple__image"
                            />
                        </a>

                        <a href={externalUrls.playStore}>
                            <img
                                alt="img"
                                src={playStoreButton}
                                className="store-play__image"
                            />
                        </a>
                        {/* <a href={externalUrls.signup}>
                        <Button className="nav-button">SIGN UP</Button>
                    </a>
                    <a href={externalUrls.login}>
                        <Button className="nav-button" secondary>
                            LOG IN
                        </Button>
                    </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar
